<template>
  <div class="container">
    <div id="nav">
      <img src="@/assets/logo.png" width="50" style="position: absolute; right: 10px; top: 25px;" alt="binary" />
      <button class="menu-toggle" @click="toggleMenu" v-bind:class="(menuOpen) ? 'opened' : ''">
        <svg width="70" height="70" viewBox="0 0 100 100">
          <path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
          <path class="line line2" d="M 20,50 H 80" />
          <path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
        </svg>
      </button>
    </div>
    <div class="menu" v-if="menuOpen">
      <div class="nav-item" v-if="menuOpen" @click="toggleMenu">
        <router-link to="/about">About the company</router-link>
      </div>
      <div class="nav-item" v-if="menuOpen">
        <router-link to="/projects" @click="toggleMenu">Projects</router-link>
      </div>
      <div class="nav-item" v-if="menuOpen" @click="toggleMenu">
        <router-link to="/contact">Contact</router-link>
      </div>
    </div>
    <div class="content">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      menuOpen: false
    }
  },
  mounted() {
    this.$router.push("/about");
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    }
  }
}
</script>

<style>
.nav-item {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
  text-align: left;
  padding-left: 25px;
}
.container {
  width: 100%;
  height: 100%;
}

.separator {
  color: white;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
}

.content {
  margin-top: 20px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
}

#nav {
  height: 100px;
  background-color: #1d252c;
  /*display: flex;
  align-items: center;
  justify-content: center;*/
}

.nav-item a {
  font-weight: bold;
  color: #8d8c8c;
  font-size: 16pt;
}

.nav-item a.router-link-exact-active {
  color: #f8fafc;
}

.menu {
  background-color: #1d252c;
  width: 100%;
  position: absolute;
  top: 100px;
  left: 0px;
  z-index: 100000000000;
  padding-bottom: 10px;
}

.menu-toggle {
  background-color: transparent;
  border: none;
  cursor: pointer;
  /*display: flex;*/
  padding: 0;
  position: absolute;
  left: 10px;
  top: 20px;
}
.line {
  fill: none;
  stroke: white;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
</style>
