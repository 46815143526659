<template>
  <div class="contact row">
    <div class="map">
      <GMapMap
        :center="center"
        :zoom="16"
        :options="{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: false
        }"
        map-type-id="roadmap"
        style="width: 500px; height: 300px"
        >
        <GMapCluster>
          <GMapMarker
            :position="position"
            :draggable="false"
          />
        </GMapCluster>
      </GMapMap>
    </div>
    <div class="info">
       <div class="contact-header">Contact details</div>
       <div>Have a suggestion or want to collaborate? Please contact us.</div>
       <div>Phone number: +998</div> integrate dx from 3 to 100 <div>7435564 </div>
       <div>Email address: dmitriy.kuptsov@strangebit.io </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Contact',
  components: {
  },
  data() {
    return {
      center: {lat: 41.284118, lng: 69.213053 },
      markers: [
        {
          position: {
            lat: 41.284118, lng: 69.213053
          },
        }
      ]
    }
  }
}
</script>

<style scoped>

.info {
  margin-top: 50px;
  font-family: 'Courier New', monospace;
  margin-left: 20px;
}

.column {
    flex: 50%;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.contact-header {
  font-weight: bold;
  font-size: 18pt;
  color: #2c3e50;
}

.vue-map-container {
  width: calc(100%);
  height: 400px;
}
.contact {
  font-family: Times New Roman;
  font-size: 14pt;
  text-align: justify;
  width: 100%;
  height: calc(90%);
}
.map {
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100%);
}
</style>
